import React from "react"
import PopinAnimator from '../utils/PopinAnimator.js';
import { Link } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReactGA from 'react-ga';

export function Home(props) {

	ReactGA.initialize('UA-146421244-1');
	ReactGA.pageview(window.location.pathname + window.location.search);

	const handleDragStart = (e) => e.preventDefault();

	const company_spinner_items = [
  		<img src="img/logos/HS2.png" alt="HS2" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/Hitachi.png" alt="Hitachi" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/WMT.png" alt="West Midlands Trains" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/IR.png" alt="Indian Railways" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/Siemens.png" alt="Siemens" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/Thales.png" alt="Thales" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/CRRC.png" alt="CRRC" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/SMRT.png" alt="SMRT" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/NR.png" alt="Network Rail" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/WMRE.png" alt="WMRE" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/SwanseaUni.png" alt="Swansea University" onDragStart={handleDragStart} role="presentation" />,
  		<img src="img/logos/UCL.png" alt="UCL" onDragStart={handleDragStart} role="presentation" />
	];
	
	const responsive = {
  		0: { items: 3 },
	    1024: { items: 4 },
	};
	

	React.useEffect(() => {
		PopinAnimator();
		window.scrollTo(0, 0);
	}, []);

	return (
				<div className="content">

					<section className={props.isMobile ? "narrow" : "left"}>
						<h2>🙋‍♂️ About me</h2>
						<p>
							Hello. I'm Dave Kirkwood, a software developer and all round computer-geek. I spend most of my time developing railway research software for the University of Birmingham in the UK. I've a first-class degree in Computer Science from Staffordshire University and a PhD in train control algorithms from Birmingham.
						</p>

						<p>
							I've always loved programming since the age of nine and the birth of home computers. I consider software development to be a creative pursuit and love it when a plan comes together.
						</p>

						<p>
							I've been a professional developer since 2002 and specialise in Object-Oriented engineering simulations but 
							my experience covers a lot, from old school 68K assembly, C++, Java Desktop, Spring and React, to name but a few.
						</p>
						<p>
							Recently I've been doing full stack development with Java Spring and React.
						</p>

					</section>

					<section className={props.isMobile ? "narrow" : "right"}>
						<h2>🔨 Tech stack</h2>


				<div className="techstack">
					<h3>Back end</h3>
					<ul>
						<li>
							<img className="techstack popin" src="img/techstack/Apache.jpg" alt="Apache" />
							<p>Apache</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/Debian.png" alt="Debian" />
							<p>Debian</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/docker.png" alt="Docker" />
							<p>Docker</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/Java.png" alt="Java" />
							<p>Java</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/Kubernetes.png" alt="Kubernetes" />
							<p>Kubernetes</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/mariadb.png" alt="MariaDB" />
							<p>MariaDB</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/MySQL.png" alt="MySQL" />
							<p>MySQL</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/PHP.jpg" alt="PHP" />
							<p>PHP</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/Proxmox.png" alt="Proxmox" />
							<p>Proxmox</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/SpringBoot.png" alt="Spring Boot" />
							<p>Spring Boot</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/tomcat.png" alt="Tomcat" />
							<p>Tomcat</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/Ubuntu.jpg" alt="Ubuntu" />
							<p>Ubuntu</p>
						</li>
					</ul>
					<h3>Front end</h3>
					<ul>
						<li>
							<img className="techstack popin" src="img/techstack/Bootstrap.png" alt="Bootstrap" />
							<p>Bootstrap</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/css.png" alt="CSS" />
							<p>CSS</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/HTML5.png" alt="HTML5" />
							<p>HTML5</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/javascript.jpeg" alt="Javascript" />
							<p>Javascript</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/React.png" alt="React" />
							<p>React</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/ReactNative.jpg" alt="React Native" />
							<p>React Native</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/Sass.png" alt="Sass" />
							<p>Sass</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/three.png" alt="Three.js" />
							<p>Three.js</p>
						</li>
					</ul>
					<h3>Devops</h3>
					<ul>
						<li>
							<img className="techstack popin" src="img/techstack/Eclipse.png" alt="Eclipse" />
							<p>Eclipse</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/git.png" alt="Git" />
							<p>Git</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/jenkins.png" alt="Jenkins" />
							<p>Jenkins</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/Maven.png" alt="Maven" />
							<p>Maven</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/npm.png" alt="NPM" />
							<p>NPM</p>
						</li>
						<li>
							<img className="techstack popin" src="img/techstack/Postman.png" alt="Postman" />
							<p>Postman</p>
						</li>
					</ul>
				</div>

					</section>

					<section className={props.isMobile ? "narrow" : "left"}>
						<h2>👩🏽‍🚀 Projects</h2>
						<h3>BRaVE (University of Birmingham Railway Simulator)</h3>
						<p>
							BRaVE is a railway simulator that has been used to help HS2 design their timetable. 
							It's 1/2 million lines of code Java Desktop application capable of simulating railway networks with different train types, driver styles and signalling systems.
						</p>
						<p>
							Features of BRaVE:
						</p>
						<ul>
							<li className="popin">man-in-the-loop with its own custom built Cab;</li>
							<li className="popin">hardware in the loop connecting in signalling systems from China;</li>
							<li className="popin">plugin railway control algorithms: the topic of my PhD;</li>
							<li className="popin">Monte-carlo simulation.;</li>
						</ul>
						<p>
							Screenshots
						</p>
				<div className="screenshots">
					<ul>
						<li>
							<Zoom>
							    <picture>
							      <img
							        alt="BRaVE Screen shot"
							        src="img/brave/BraveScreen1.png"
							        width="500"
							      />
							    </picture>
							</Zoom>
							<p>Speed profile</p>
						</li>
						<li>
							<Zoom>
							    <picture>
							      <img
							        alt="BRaVE Screen shot"
							        src="img/brave/BraveScreen2.png"
							        width="500"
							      />
							    </picture>
							</Zoom>
							<p>Main sim view</p>
						</li>
						<li>
							<Zoom>
							    <picture>
							      <img
							        alt="BRaVE Screen shot"
							        src="img/brave/BraveScreen3.png"
							        width="500"
							      />
							    </picture>
							</Zoom>
							<p>Cab controls 1</p>
						</li>
						<li>
							<Zoom>
							    <picture>
							      <img
							        alt="BRaVE Screen shot"
							        src="img/brave/BraveScreen4.png"
							        width="500"
							      />
							    </picture>
							</Zoom>
							<p>ETCS DMI</p>
						</li>
						<li>
							<Zoom>
							    <picture>
							      <img
							        alt="BRaVE Screen shot"
							        src="img/brave/BraveScreen5.png"
							        width="500"
							      />
							    </picture>
							</Zoom>
							<p>Cab view</p>
						</li>
						<li>
							<Zoom>
							    <picture>
							      <img
							        alt="BRaVE Screen shot"
							        src="img/brave/BraveScreen6.png"
							        width="500"
							      />
							    </picture>
							</Zoom>
							<p>Cab controls 2</p>
						</li>
					</ul>
				</div>
				<div className="more">
					<Link to="/BRaVE" className="more-button">More about BRaVE...</Link>
				</div>
				
					</section>
					<section className={props.isMobile ? "narrow" : "right"}>

						<h2>💻 Work history</h2>

						<h3>2014 - Present: Software developer, Railway research group, University of Birmingham</h3>
						<ul>
							<li className="popin">Wrote BRaVE railway simulator</li>
							<li className="popin">Taught MSc railway signalling modules; Supervised PhD projects</li>
							<li className="popin">Wrote papers; Spoke at conferences</li>
							<li className="popin">Had the pleasure to work with these fine people...</li>
						</ul>
					<div className="companies">
						<AliceCarousel
						        autoPlay
        						autoPlayStrategy="none"
        						autoPlayInterval={1000}
        						animationDuration={400}
        						infinite
        						touchTracking={false}
        						disableDotsControls
        						disableButtonsControls
        						responsive={responsive}
        						items={company_spinner_items} />
					</div>
						<h3>2004 - 2013: Simulation software developer, Graffica Ltd, UK</h3>
						<ul>
							<li className="popin">Wrote HERMES commercial railway simulator</li>
							<li className="popin">Worked on eDep Air Traffic control prototype system</li>
							<li className="popin">Integrated Cockpit software with ATC systems</li>
							<li className="popin">Wrote Radar message converters (ADS-B and TIS-B) provided to BAE Systems</li>
						</ul>
						<h3>2002 - 2004: Software developer, National Express coaches, UK</h3>
						<ul>
							<li className="popin">Call centre system development</li>
							<li className="popin">Upgraded VB6 systems to .NET</li>
							<li className="popin">Data warehousing</li>
						</ul>
						<h3>2000 - 2001: Software developer, Peach Data services, Stoke-on-Trent</h3>
						<ul>
							<li className="popin">Developed Bentley cars store stock control system.</li>
							<li className="popin">Bespoke web back end development.</li>
						</ul>
					</section>

					<section className={props.isMobile ? "narrow" : "left"}>
						<h2>👨‍🎓 Education</h2>
						<h3>2010 - 2014: PhD, University of Birmingham</h3>
						<ul>
							<li className="popin">Thesis title: Simulation and testing of railway traffic management and train control algorithms.</li>
						</ul>
						<h3>2000 - 2002: BSc (Hons) Computing Science, Staffordshire University</h3>
						<ul>
							<li className="popin">First class Honours</li>
							<li className="popin">AI, Chaos and Fractals, Mobile Apps, Advanced OO, Image processing, VR, Web Apps.</li>
						</ul>
						<h3>1998 - 2000: HND Computing, Worcester College of Technology</h3>
						<ul>
							<li className="popin">11 Distinctions; 3 Merits; 1 Pass</li>
						</ul>

					</section>

					<section className={props.isMobile ? "narrow" : "right"}>
						<h2>📜 Publications</h2>
						<ul>
							<li className="popin">Wang, Y., Chen, L., Kirkwood, D., Fu, P., lv, J. & Roberts, C., 25 Jul 2018, Hybrid Online Model-Based Testing for Communication-Based Train Control Systems, In: IEEE Intelligent Transportation Systems Magazine. 10, 3, p. 35-47 13 p.</li>
							<li className="popin">Douglas, H., Weston, P., Kirkwood, D., Hilmansen, S., Roberts, C., 2016, Method for validating the train motion equations used for passenger rail vehicle simulation, Proceedings of the Institution of Mechanical Engineers, Part F: Journal of Rail and Rapid Transit, February 15.</li>
							<li className="popin">Nicholson, G., Kirkwood, D., Roberts, C., Schmid, F., 2015, Benchmarking and evaluation of railway operations performance, Journal of Rail Transport Planning & Management, vol 5, issue 4 (2015).</li>
							<li className="popin">Kirkwood, D., Nicholson, G., Chen, L., Roberts, C., Schmid, F., 2015, Evaluation and testing of railway traffic management algorithms using microscopic simulation, 6th international conference on Railway Operations Modelling and Analysis, IAROR, Tokyo.</li>
							<li className="popin">Umiliacchi, S., Nicholson, G., Kirkwood, D., Zhao, N., Schmid, F., Roberts, C., 2015, An integrated methodology using micro- and macro-simulation, performance evaluation and optimisation to design energy-efficient timetables, 6th international conference on Railway Operations Modelling and Analysis, IAROR, Tokyo.</li>
							<li className="popin">Wen, T., Lyu, X., Kirkwood, D., Chen, L., Constantinou, C., Roberts, C., 2015, Co-Simulation Testing of Data Communication System supporting CBTC, 2015 IEEE 18th International Conference on Intelligent Transportation Systems, Las Palmas de Gran Canaria, Canary Islands, Spain</li>
							<li className="popin">Kirkwood, D., Roberts, C., Schmid, F., 2013, Validation of railway network simulation software, 5th international conference on Railway Operations Modelling and Analysis, IAROR, Copenhagen.</li>
						</ul>
					</section>


					<blockquote>
						<p>Thanks for reading!</p>
					</blockquote>

				</div>
	)
}