import React from "react"
import PopinAnimator from '../utils/PopinAnimator.js';
import ReactGA from 'react-ga';

export function Other(props) {

	ReactGA.initialize('UA-146421244-1');
	ReactGA.pageview(window.location.pathname + window.location.search);

	React.useEffect(() => {
		PopinAnimator();
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="content">

			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>👨‍💻 Others</h2>
				<p>
					This page contains links for games, other websites and applications. All of the items on this page are written by me. 
				</p>

				<ul>
					<li className="popin">
						<a href="#games">Go to the Games section.</a>
					</li>
					<li className="popin">
						<a href="#websites">Go to the Websites section.</a>
					</li>
					<li className="popin">
						<a href="#apps">Go to the Apps section.</a>
					</li>
				</ul>

			</section>

			<div id="games" className="anchor-offset" />
			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>🕹️ Games</h2>
			</section>

			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>Space Invaders</h2>
				<p>
					An classic from 1978.
				</p>

				<div className="bravepic f_right">
					<a href="games/si/SpaceInvaders.html"><img alt="Space Invaders" src="games/SpaceInvaders.jpg" /></a>
					<p>
						Space Invaders
					</p>
				</div>
				<p>
					A few little known facts about this game: the higher levels are impossible, so in order to get the highest score it is necessary to get high scoring bonus ships. If you fire off 22 missiles and wait for the first bonus ship, it will be worth 400 points. Hit the bonus ship with the 23rd missile. Then, continue to fire off 14 missiles and hit the bonus ship with the 15th. Repeat this until the aliens are almost down at base level. The bonus ships will stop coming if there are less than 8 aliens left.
				</p>
				<p>
					Another tip is that the aliens cannot shoot you if they are at their lowest level.
				</p>
				<p>
					This info is all correct in the original arcade game.
				</p>
				<p>
					Use the cursor keys to move and the space bar to fire.
				</p>
			</section>

			<section className={props.isMobile ? "narrow" : "right"}>
				<h2>Snake</h2>
				<p>
					Another classic from 1970s.
				</p>

				<div className="bravepic f_left">
					<a href="games/snake/index.html"><img alt="Snake" src="games/snake.jpg" /></a>
					<p>
						Snake
					</p>
				</div>
				<p>
					Eat food and avoid your tail that grows when you eat. Use the space bar to start and cursor keys to move the snake. You can go off the edge of the screen and will return on the opposite side.
				</p>
			</section>

			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>Sudoku</h2>
				<p>
					Fill the grid with numbers 1-9 on each row, each column and each 3x3 box.
				</p>

				<div className="bravepic f_right">
					<a href="games/sudoku2/index.html"><img alt="Sudoku" src="games/Sudoku2.png" /></a>
					<p>
						Sudoku
					</p>
				</div>
				<p>
					The game has four levels of difficulty, from easy to very hard. There is also the option to enter in an existing puzzle and get the app to solve it for you.
				</p>
				<p>
					Corner and centre pencil marks can be added by using the buttons at the top of the grid on the right of the game area.
				</p>
				<p>
					If you are interested to know how the game was made, there is a youtube video about it <a href="https://youtu.be/0YqHCaULjP4">here</a>.
				</p>

			</section>

			<div id="websites" className="anchor-offset" />
			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>🌐 Websites</h2>
			</section>

			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>Language site</h2>
				<div className="bravepic f_right">
					<a href="https://www.katyakirkwood.com/"><img alt="Katya Kirkwood" src="games/LanguageSite.png" /></a>
					<p>
						www.katyakirkwood.com
					</p>
				</div>
					<p>
						My Wife's language website. She's an excellent teacher and has glowing reviews from many of her students.
					</p>
					<p>
						I look after her website. I host it myself. It's done with React and Sass and has a spring backend.
					</p>
			</section>

			<section className={props.isMobile ? "narrow" : "right"}>
				<h2>Worcester now and then</h2>
				<p>
					Something a bit different.
				</p>

				<div className="bravepic f_left">
					<a href="games/WorcesterNowAndThen/index.html"><img alt="Worcester now and then" src="games/WrCathedral.jpg" /></a>
					<p>
						Worcester now and then
					</p>
				</div>
				<p>
					Worcester is my hometown. Its a beautiful historic city in the middle of England. In 2008 I found a load of old photos of Worcester and decided to go and recreate a set of them to see how the town had changed. On this page, you can see old photos of Worcester. You can hover the mouse over the photos to see what it looks like now.
				</p>
				<p>
					I thought the job would be easy but it turns out that picking the right spot to stand in and getting the perspective the same as the original photos was more challenging than I thought.
				</p>
			</section>
			
			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>Youtube channel</h2>
				<div className="bravepic f_right">
					<a href="https://www.youtube.com/channel/UCeTnHKGiEIyMBnsEnwBU2Nw"><img alt="My youtube channel" src="img/Youtube.png" /></a>
					<p>
						My Youtube channel
					</p>
				</div>
					<p>
						My youtube channel is mostly focussed on simple Java tutorials.
					</p>
					<p>
						The beginner videos will work through the basics of Java. They are designed to be a complete course in Java programming, starting from the very first hello world project through to more advanced topics.
					</p>
					<p>
						The projects videos will include cool projects, such as graphical effects, games and fun applications. 
					</p>
					<p>
						I also have Codility solutions that are useful practice for programming interviews.
					</p>
			</section>

			<div id="apps" className="anchor-offset" />
			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>💻 Apps</h2>
			</section>

			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>Wordle Solver</h2>
				<p>
					Cheat on Wordle with this solver application.
				</p>

				<div className="bravepic f_right">
					<a href="../games/wordlesolver/WordleSolver.zip"><img alt="Wordle solver app" src="games/Wordle.png" /></a>
					<p>
						Wordle solver app
					</p>
				</div>
				<p>
					The solver builds a search tree with all of the words in Wordle, it searches through the tree to find the shallowest branches and then suggests the words that will lead you down the branches to the correct result.
				</p>
				<p>
					Run the app alongside wordle. Enter the words it suggests and then click on the squares to mark them orange or green. Hit submit and then get the next suggested word.
				</p>

				<ul>
					<li className="popin">
						<a href="https://github.com/davekirkwood/wordlesolver">Download the Source Code.</a>
					</li>
					<li className="popin">
						<a href="https://youtu.be/4YpaHVDufhg">
						See how it works.
						</a>
					</li>
					<li className="popin">
						<a href="../games/wordlesolver/WordleSolver.zip">
							Download the app. (Unzip and double click RUN_ME.bat).
						</a>
					</li>
				</ul>
			</section>




		</div>
	)
}