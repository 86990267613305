import React from "react"
import PopinAnimator from '../utils/PopinAnimator.js';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import ReactGA from 'react-ga';

export function Brave(props) {

	ReactGA.initialize('UA-146421244-1');
	ReactGA.pageview(window.location.pathname + window.location.search);

	React.useEffect(() => {
		PopinAnimator();
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="content">

			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>🚅 BRaVE</h2>
				<p>
					Universities and businesses are developing and evolving novel concepts in modern railways in order to achieve greater punctuality, resilience and robustness in scheduled train operations. These include advanced signalling technologies, driver advisory and traffic management automation systems.
				</p>

				<div className="bravepic f_right">
					<Zoom>
					    <picture>
					      <img
					        alt="BRaVE Driver Cab"
					        src="img/brave/BRaVECab.jpg"
					        width="500"
					      />
					    </picture>
					</Zoom>
					<p>
						BRaVE Driver Cab
					</p>
				</div>
				<p>
					BRAVE is a suite of software tools featuring a microscopic synchronous core.
					It is a virtual environment that can be used for teaching railway principles and for research purposes, such as prototyping, concept development, performance analysis and benchmarking.
				</p>

				<p>
					BRaVE is a microscopic railway network simulator.
					BRaVE takes the infrastructure, interlocking, train physics and timetable as an input.
					It then simulates the movement of trains in timesteps (typically one second) to attempt to achieve the timetable within the constraints of the train physics and interlocking systems.
				</p>

				<div className="bravepic f_left">
					<Zoom>
					    <picture>
					      <img
					        alt="BRaVE Mini Cab"
					        src="img/brave/MiniCab.jpg"
					        width="500"
					      />
					    </picture>
					</Zoom>
					<p>
						BRaVE Mobile Cab
					</p>
				</div>

				<p>
					BRaVE is written in Java and follows Object-Oriented design principles.
					Elements of the railway network are modelled as POJOs that follow the observer design pattern to mimic the behaviour of the real world railway.
					The expertise coded into BRaVE has come from years of working with smart folk at the University and in industry.
				</p>

				<p>
					BRaVE was featured in <a href="https://www.railtechnologymagazine.com/Comment/brave-modelling-of-notional-wirksworth-to-derby-community-rail-scheme"> RTM magazine</a>.
				</p>
			</section>
			<section className={props.isMobile ? "narrow" : "right"}>
				<h2>🎥 Demos</h2>
				<p>
					See the videos below to see BRaVE in action, including the East-coat mainline, Hefei Metro, power simulation, comms simulation and the Cross City line cab.
				</p>


				<div className="vidpanel">
					<img className="vidimage" alt="Brave in action" src="https://img.youtube.com/vi/ErQFVOuuaPo/default.jpg" />
					<div className="vidtime">4:03</div>
					<div className="play-btn">
						<a href="https://www.youtube.com/watch?v=ErQFVOuuaPo" target="_blank" rel="noreferrer"> <svg width="80px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"> <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" /> <path className="play-btn__svg" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z" /> </svg>
						</a>
					</div>
					<a className="vidtext" href="https://www.youtube.com/watch?v=ErQFVOuuaPo" target="_blank" rel="noreferrer">BRaVE in action</a>
				</div>

				<div className="vidpanel">
					<img className="vidimage" alt="Driver cab" src="https://img.youtube.com/vi/APR7UXKW_1g/default.jpg" />
					<div className="vidtime">2:42</div>
					<div className="play-btn">
						<a href="https://www.youtube.com/watch?v=APR7UXKW_1g" target="_blank" rel="noreferrer"> <svg width="80px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"> <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" /> <path className="play-btn__svg" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z" /> </svg>
						</a>
					</div>
					<a className="vidtext" href="https://www.youtube.com/watch?v=APR7UXKW_1g" target="_blank" rel="noreferrer">Driver Cab</a>
				</div>

				<div className="vidpanel">
					<img className="vidimage" alt="Passenger simulation" src="https://img.youtube.com/vi/PMXMwRVGRRk/default.jpg" />
					<div className="vidtime">1:33</div>
					<div className="play-btn">
						<a href="https://www.youtube.com/watch?v=PMXMwRVGRRk" target="_blank" rel="noreferrer"> <svg width="80px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"> <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" /> <path className="play-btn__svg" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z" /> </svg>
						</a>
					</div>
					<a className="vidtext" href="https://www.youtube.com/watch?v=PMXMwRVGRRk" target="_blank" rel="noreferrer">Passenger simulation</a>
				</div>

				<div className="vidpanel">
					<img className="vidimage" alt="Updated cab graphics" src="https://img.youtube.com/vi/m9P3A1VijqE/default.jpg" />
					<div className="vidtime">8:45</div>
					<div className="play-btn">
						<a href="https://www.youtube.com/watch?v=m9P3A1VijqE" target="_blank" rel="noreferrer"> <svg width="80px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"> <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" /> <path className="play-btn__svg" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z" /> </svg>
						</a>
					</div>
					<a className="vidtext" href="https://www.youtube.com/watch?v=m9P3A1VijqE" target="_blank" rel="noreferrer">Updated Cab graphics</a>
				</div>


			</section>
			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>👩🏽‍🚀 Research Projects</h2>
				<p>A non-exhaustive list of research projects for which BRaVE output has contributed to.</p>
				<div className="bravepic f_right">
					<Zoom>
					    <picture>
					      <img
					        alt="World Congress lecture on BRaVE"
					        src="img/brave/BRaVELab.jpg"
					        width="500"
					      />
					    </picture>
					</Zoom>
					<p>
						Me showing off BRaVE's HS2 model at the World Congress of Railway Research, 2022
					</p>
				</div>
				<ul>
					<li className="popin">2020-2023: PERFORMINGRAIL: PERformance-based Formal modelling and Optimal tRaffic Management for movING-block RAILway signalling - European Commission</li>
					<li className="popin">2019-2022: Research on High Speed Maglev System Graphical Modelling and Design Simulation Optimisation Platform - CRRC</li>
					<li className="popin">2020-2021: Analysis of Line Capacity Upgrade with ETCS L2 on the Jhansi Bina Section of the Indian North Central Railway - Indian Railways</li>
					<li className="popin">2017-2018: Power System Dynamic loading simulation study - SMRT</li>
					<li className="popin">2017-2018: Traffic Management Simulation for Siemens' Signalling Systems</li>
					<li className="popin">2014-2017: Developing and Evaluating Dynamic Optimisation for Train control systems (DEDOTS) - RSSB and UCL</li>
				</ul>
			</section>
			<section className={props.isMobile ? "narrow" : "right"}>
				<h2>👨‍🏫 Teaching</h2>
				<div className="bravepic f_right">
					<Zoom>
					    <picture>
					      <img
					        alt="Lecture on BRaVE"
					        src="img/brave/BeBrave.jpg"
					        width="500"
					      />
					    </picture>
					</Zoom>				
				</div>
				<p>
					BRaVE is used to teach signalling principles via team exercises for the following University of Birmingham MSc programmes.
				</p>
				<ul>
					<li className="popin">2018-2022 MSc Railways Dissertation supervision.</li>
					<li className="popin">2021-2022 LM Railway Control and Digital Systems and Principles of Railway Control Systems</li>
					<li className="popin">2021-22 Railway Systems Engineering and Integration</li>
					<li className="popin">2020-2021 LM Railway Control and Digital Systems and Principles of Railway Control Systems</li>
					<li className="popin">2019-20 LMR36 Railway Control Systems Engineering</li>
					<li className="popin">2019-20 LMR66B Applications of Railway Control Systems</li>
					<li className="popin">2018-19 LMR136 SMRT Railway Technology: Command, Control, and Communications II</li>
				</ul>
			</section>


		</div>
	)
}