
/**
 * Popin animator function watches elements with <SELECTOR> class and adds an
 * <ANIMATE_CLASS_NAME> class to the element when the item is made visible in the
 * viewport.
 *
 * Can be used with the following example CSS styles to fade in when the element
 * is visible on screen.
 * 
 * .popin { opacity: 0; transition: opacity 2s; }
 * .popin.animate { opacity: 1; }
 */

export default function PopinAnimator() {
	const SELECTOR = '.popin';
	const ANIMATE_CLASS_NAME = 'animate';
	
	const animate = element => (
	  element.classList.add(ANIMATE_CLASS_NAME)
	);
	
	const isAnimated = element => (
	  element.classList.contains(ANIMATE_CLASS_NAME)
	);
	
	const intersectionObserver = new IntersectionObserver((entries, observer) => {
	  entries.forEach((entry) => {
	    
	    // when element's is in viewport,
	    // animate it!
	    if (entry.intersectionRatio > 0) {

	      animate(entry.target);
	      // remove observer after animation
	      observer.unobserve(entry.target);
		}
	  });
	});
	
	// get only these elements,
	// which are not animated yet
	const elements = [].filter.call(
	  document.querySelectorAll(SELECTOR),
	  element => !isAnimated(element, ANIMATE_CLASS_NAME)
	);
	
	// Would like to randomise time on transition.
	//console.log(elements);
//	elements.forEach((element) => {
		//console.log(element.style.getPropertyValue('transition'));
		//element.style.transition = 'opacity ' + Math.random() * 30 + 's';
	//});
	
	// start observing your elements
	elements.forEach((element) => intersectionObserver.observe(element));
	
	  
}