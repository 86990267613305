
import React from "react";
import './App.css';
import './scene.js';
import { Header } from './panels/Header';
import { Home } from './panels/Home';
import { Brave } from './panels/Brave';
import { Other } from './panels/Other';
import { Contact } from './panels/Contact';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Footer } from './panels/Footer';

function App() {

	const [isMobile, setIsMobile] = React.useState(false)

	//choose the screen size 
	const handleResize = () => {
		if (window.innerWidth < 1020) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}

	React.useEffect(() => {
		window.addEventListener("resize", handleResize);
	}, []);


	return (
		<div className="App">
			<Router>
				<Header/>
				<div className="content-outer">
					<Routes>
						<Route path="/Brave" element={<Brave isMobile={isMobile}/>}/>
						<Route path="/Other" element={<Other isMobile={isMobile}/>}/>
						<Route path="/Contact" element={<Contact isMobile={isMobile}/>}/>
						<Route path="/*" element={<Home isMobile={isMobile}/>}/>
					</Routes>
					<Footer/>
				</div>
			</Router>
		</div>
	);

}

export default App
