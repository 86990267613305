
const API_BASE = process.env.REACT_APP_API_BASE;

export function api_submitContactForm(formData, setFormStatus) {

	console.log(process.env.REACT_APP_API_BASE);
	setFormStatus({
		message: ""
	})


	fetch(API_BASE + "contact", {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	}).then((response) => {
		return response.json()
	}, (error) => {
		if (error) {
			setFormStatus(
				{
					message: "Problem with server, please try again later.",
					fieldErrors: [

					]
				})
		}
	}).then(data => {
		if(data.fieldErrors !== undefined) {
			var fieldErrors = Object.keys(data.fieldErrors)
			setFormStatus({
				message: data.message,
				fieldErrors: JSON.stringify(fieldErrors)
			})
		} else {
			setFormStatus({
				message: data.message
			})
		}

	});

}
