
import React from "react"
import { Link } from 'react-router-dom';

export function Header() {

	return (
		<header>
			<h1>Dave Kirkwood</h1>
			
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
			<div className="socialm">
				<a href="https://www.facebook.com/davekirkwood73/" className="fa fa-facebook"> </a>
				<a href="https://www.linkedin.com/in/david-kirkwood-27349964/" className="fa fa-linkedin"> </a>
				<a href="https://www.youtube.com/channel/UCeTnHKGiEIyMBnsEnwBU2Nw" className="fa fa-youtube"> </a>
				<a href="https://twitter.com/davekirkwood" className="fa fa-twitter"> </a>
				<a href="https://github.com/davekirkwood" className="fa fa-github"> </a>
				<a href="https://odysee.com/$/invite/@davekirkwood:6"><img src="img/odysee.svg" className="custom-sm-odysee" alt="Odysee"/></a>
			</div>

			<div className="linkbox">
				<Link className="link-button" to="/">Home</Link>
				<Link className="link-button" to="/BRaVE">BRaVE</Link>
				<Link className="link-button" to="/Other">Other</Link>
				<Link className="link-button" to="/Contact">Contact</Link>
			</div>
		</header>
	)
}