
import React from "react"

export function Footer() {

	return (
			<footer>
				<p>© 2022 Dave Kirkwood</p>
				<div className="socialm">
				<a href="https://www.facebook.com/davekirkwood73/" className="fa fa-facebook"> </a>
				<a href="https://www.linkedin.com/in/david-kirkwood-27349964/" className="fa fa-linkedin"> </a>
				<a href="https://www.youtube.com/channel/UCeTnHKGiEIyMBnsEnwBU2Nw" className="fa fa-youtube"> </a>
				<a href="https://twitter.com/davekirkwood" className="fa fa-twitter"> </a>
				<a href="https://github.com/davekirkwood" className="fa fa-github"> </a>
				<a href="skype:davekirkwood" className="fa fa-skype"> </a>
				<a href="https://odysee.com/$/invite/@davekirkwood:6"><img src="img/odysee.svg" className="custom-sm-odysee" alt="Odysee"/></a>
				</div>
			</footer>
	)
}