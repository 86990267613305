import React from "react"
import { api_submitContactForm } from '../api/Api';
import ReactGA from 'react-ga';

export function Contact(props) {

	ReactGA.initialize('UA-146421244-1');
	ReactGA.pageview(window.location.pathname + window.location.search);

	const [formData, setFormData] = React.useState({
		name: "",
		email: "",
		subject: "",
		message: ""
	})
	
	const [formSubmitted, setFormSubmitted] = React.useState(false);

	const [formStatus, setFormStatus] = React.useState();

	function handleChange(event) {
		const { name, value } = event.target
		setFormSubmitted(false);
		setFormData(prevData => {
			return {
				...prevData,
				[name]: value
			}
		})
	}

	function handleSubmit(event) {
		event.preventDefault()		//Don't re-render
		//props.login(formData)
		api_submitContactForm(formData, setFormStatus);
		console.log(formData);
		setFormSubmitted(true);
	}

	return (
		<div className="content">

			<section className={props.isMobile ? "narrow" : "left"}>
				<h2>💬 Contact me</h2>
					<p>Get in touch and I will get back to you soon.</p>

				<form className="contact-form" onSubmit={handleSubmit}>
					<div className="contact-form-name">
						<p>Your Name:</p>
						<span className={formStatus && formStatus.fieldErrors && formStatus.fieldErrors.includes("name") && "contact-form-error"}><input name="name" placeholder="Name" type="text" id="name" onChange={handleChange} value={formData.name} /></span>
					</div>
					<div className="contact-form-email">
					
						<p>Your Email:</p>
						<span className={formStatus && formStatus.fieldErrors && formStatus.fieldErrors.includes("email") && "contact-form-error"}><input name="email" placeholder="Email" type="email" id="email" onChange={handleChange} value={formData.email} /></span>
					</div>
					<div className="contact-form-subject">
						<p>Subject:</p>
						<span className={formStatus && formStatus.fieldErrors && formStatus.fieldErrors.includes("subject") && "contact-form-error"}><input name="subject" placeholder="Subject" type="text" id="subject" onChange={handleChange} value={formData.subject} /></span>
					</div>
					<div className="contact-form-messageHeader">
						<p>Message:</p>
					</div>
					<div className="contact-form-message">
						<span className={formStatus && formStatus.fieldErrors && formStatus.fieldErrors.includes("message") && "contact-form-error"}><textarea name="message" placeholder="Your message" id="message" onChange={handleChange} value={formData.message} ></textarea></span>
					</div>
					<div className="contact-form-submitButton">
						{!formSubmitted && 
							<button id="answerbutton">Send message &#10095;</button>
						}
					</div>
				</form>
						{formSubmitted && formStatus != null &&
							<p>{formStatus.message}</p>
						}

			</section>
		</div>
	)
}