import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
const renderer = new THREE.WebGLRenderer({
	canvas: document.querySelector('#bg')
});

renderer.setPixelRatio( window.devicePixelRatio );
renderer.setSize( window.innerWidth, window.innerHeight );
//camera.position.setZ(30);

// Create Torus shape.
/*
const geometry = new THREE.TorusGeometry( 10, 3, 16, 100 );
//const material = new THREE.MeshBasicMaterial( { color: 0xFF6347, wireframe: true });
const material = new THREE.MeshStandardMaterial( { color: 0x000047 });
const torus = new THREE.Mesh( geometry, material );
scene.add(torus);
*/
const earthMap = new THREE.TextureLoader().load("img/earthmap1k.jpg"); 
const earth = new THREE.Mesh(
	new THREE.SphereGeometry(200, 36, 36),
	new THREE.MeshStandardMaterial( {
		map: earthMap
	})
);

//earth.position.setX(7);
//earth.position.setY(1);
earth.position.setZ(-300);
scene.add(earth);

// Point light
const pointLight = new THREE.PointLight(0xffffff);
pointLight.position.set(50, 5, 5);

// Ambient light to light whole scene.
//const ambientLight = new THREE.AmbientLight(0x220000);
scene.add(pointLight);

// Show location of light.
//const lightHelper = new THREE.PointLightHelper(pointLight);
//scene.add(lightHelper);

// Grid helper
//const gridHelper = new THREE.GridHelper(200,50);
//scene.add(gridHelper);

// Orbit controls (imported)
const controls = new OrbitControls(camera, renderer.domElement);

// Starfield
function addStar() {
	const geometry = new THREE.SphereGeometry(0.1, 24, 24);
	const material = new THREE.MeshStandardMaterial( { color: 0xffffff });
	const star = new THREE.Mesh( geometry, material );
	const [x, y, z] = Array(3).fill().map(() => THREE.MathUtils.randFloatSpread(100));
	star.position.set(x, y, z);
	scene.add(star);
}

Array(800).fill().forEach(addStar);

/*
 * Background image
 */
//const texture = new THREE.TextureLoader().load("img/purplespace.jpg");
const texture = new THREE.TextureLoader().load("img/space2.jpg");
scene.background = texture;

/*
 * Moon
 */
/*
const moonMap = new THREE.TextureLoader().load("img/moon_map.jpg"); 
const moonTexture = new THREE.TextureLoader().load("img/moon_normalmap.jpg");
const moon = new THREE.Mesh(
	new THREE.SphereGeometry(3, 32, 32),
	new THREE.MeshStandardMaterial( {
		map: moonMap,
		normalMap: moonTexture
	})
);

moon.position.setX(-20);
moon.position.setZ(-200);

scene.add(moon);
*/
const daveboxTexture = new THREE.TextureLoader().load('img/KirkwoodDavid.jpg');
const davebox = new THREE.Mesh(
	new THREE.BoxGeometry(0.1,3,3),
	new THREE.MeshBasicMaterial({ map: daveboxTexture })
)


davebox.position.setX(8);
davebox.position.setY(1);
davebox.position.setZ(-10);


scene.add(davebox);


// Animate method
function animate() {
	requestAnimationFrame( animate );
	
	renderer.setSize( window.innerWidth, window.innerHeight );
        
//	torus.rotation.x += 0.01;
	//torus.rotation.y += 0.01;
	//torus.rotation.z += 0.01;
	
	davebox.rotation.y += 0.01;
	
	earth.rotation.y -= 0.0001;
	
	controls.update();
	
	renderer.render( scene, camera );
}

animate();

function moveCamera() {
	var t = document.body.getBoundingClientRect().top;
/*	moon.rotation.x += 0.05;
	moon.rotation.y += 0.075;
	moon.rotation.z += 0.05;*/
	
	camera.position.z = 10 + (t * -0.02);
//	camera.position.x = t * -0.02;
//	camera.position.y = t * -0.0002;
	
	davebox.position.setZ((t * -0.02));

	
}

document.body.onscroll = moveCamera;
moveCamera();